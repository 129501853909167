(() => {
    document.querySelector("#contact-form").addEventListener("submit", (event) => {
        event.preventDefault();

        console.log(`main_index.js Email Processor v1.0.99`);

        // Get form elements
        const nameField = event.target.elements.name;
        const emailField = event.target.elements.email;
        const subjectField = event.target.elements.subject;
        const messageField = event.target.elements.message;
        const contactConsole = document.getElementById("contact-console");

        // Email validation regex
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        // Validation checks
        if (!nameField.value.trim()) {
            if (contactConsole) {
                contactConsole.style.color = "#f00404";
                contactConsole.innerHTML = "Error: Name field cannot be empty.";
            }
            return;
        }

        if (!emailField.value.trim()) {
            if (contactConsole) {
                contactConsole.style.color = "#f00404";
                contactConsole.innerHTML = "Error: Email field cannot be empty.";
            }
            return;
        }

        if (!emailRegex.test(emailField.value.trim())) {
            if (contactConsole) {
                contactConsole.style.color = "#f00404";
                contactConsole.innerHTML = "Error: Please enter a valid email address.";
            }
            return;
        }

        if (!subjectField.value.trim()) {
            if (contactConsole) {
                contactConsole.style.color = "#f00404";
                contactConsole.innerHTML = "Error: Subject field cannot be empty.";
            }
            return;
        }

        if (!messageField.value.trim()) {
            if (contactConsole) {
                contactConsole.style.color = "#f00404";
                contactConsole.innerHTML = "Error: Message field cannot be empty.";
            }
            return;
        }

        var TotalMessage = `
            CONTACT REQUEST
            Name: ${nameField.value}
            Email: ${emailField.value}
            Subject: ${subjectField.value}
            Message: ${messageField.value}
        `;

        POSTHandler(TotalMessage)
            .then((response) => {
                if (response.success) {
                    if (contactConsole) {
                        contactConsole.style.color = "#0c9b42";
                        contactConsole.innerHTML = "Message sent!";
                    }

                    // Reset form fields
                    nameField.value = "";
                    emailField.value = "";
                    subjectField.value = "";
                    messageField.value = "";

                    console.log(`Contact SUCCESS: Email SENT`);
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        'event': 'email-contact-success',
                        'page_name': 'Index',
                        'page_location': 'Footer'
                    });
                } else {
                    if (contactConsole) {
                        contactConsole.style.color = "#f00404";
                        contactConsole.innerHTML = "Error: " + response.error;
                    }
                    console.log(`Contact Email FAIL: ` + response.error);
                    window.dataLayer.push({
                        'event': 'email-contact-fail',
                        'page_name': 'Index',
                        'page_location': 'Footer',
                        'error_message': response.error
                    });
                }
            })
            .catch((error) => {
                console.error("AJAX Error:", error);
                if (contactConsole) {
                    contactConsole.style.color = "#f00404";
                    contactConsole.innerHTML = "Sorry, there was an error sending your message.";
                }
                console.log(`Contact Email ERROR: ` + error.message);
                window.dataLayer.push({
                    'event': 'email-send-error',
                    'page_name': 'Index',
                    'page_location': 'Footer',
                    'error_message': error.message
                });
            });
    });

    function POSTHandler(TotalMessage) {
        const urlToGet = "/php/contact.php";

        return fetch(urlToGet, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ contactTxt: TotalMessage }),
        })
        .then((response) => {
            if (!response.ok) {
                throw new Error(`Server error: ${response.status}`);
            }
            return response.text();
        })
        .then((text) => {
            try {
                return JSON.parse(text);
            } catch (error) {
                console.error("Server returned non-JSON response:", text);
                return { success: false, error: "Invalid response from server. Please email info@protonify.com." };
            }
        })
        .catch((error) => {
            console.error("POST Error:", error);
            return { success: false, error: "Network error or server unavailable. Please email info@protonify.com." };
        });
    }
})();
/*(() => {
    document.querySelector("#contact-form").addEventListener("submit", (event) => {
        event.preventDefault();
		
		console.log(`main_index.js Email Processor v1.0.99`);
		
        var TotalMessage = `
            CONTACT REQUEST
            Name: ${event.target.elements.name.value}
            Email: ${event.target.elements.email.value}
            Subject: ${event.target.elements.subject.value}
            Message: ${event.target.elements.message.value}
        `;

        // Get form elements
        const nameField = event.target.elements.name;
        const emailField = event.target.elements.email;
        const subjectField = event.target.elements.subject;
        const messageField = event.target.elements.message;
        const contactConsole = document.getElementById("contact-console");
		//console.log(TotalMessage);
		// Email validation regex (xxx@yyy.ccc)
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        // Validation checks
        if (!nameField.value.trim()) {
            contactConsole.style.color = "#f00404";
            contactConsole.innerHTML = "Error: Name field cannot be empty.";
            return;
        }

        if (!emailField.value.trim()) {
            contactConsole.style.color = "#f00404";
            contactConsole.innerHTML = "Error: Email field cannot be empty.";
            return;
        }

        if (!emailRegex.test(emailField.value.trim())) {
            contactConsole.style.color = "#f00404";
            contactConsole.innerHTML = "Error: Please enter a valid email address.";
            return;
        }

        POSTHandler(TotalMessage)
            .then((response) => {
                if (response.success) {
                    document.getElementById("contact-console").style.color = "#0c9b42";
                    document.getElementById("contact-console").innerHTML = "Message sent!";
                    document.getElementById("name-field").value = "";
                    document.getElementById("email-field").value = "";
                    document.getElementById("subject-field").value = "";
                    document.getElementById("message-field").value = "";
                    console.log(`Contact SUCCESS: Email SENT`);
                    // Send success event to GTM
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        'event': 'email-contact-success',
                        'page_name': 'Index',
                        'page_location': 'Footer'
                    });
                    console.log(`Contact Email EVENT`);
                } else {
                    document.getElementById("contact-console").style.color = "#f00404";
                    document.getElementById("contact-console").innerHTML = "Error: " + response.error;
                    console.log(`Contact Email FAIL: ` + response.error);
                    // Send failure event to GTM
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        'event': 'email-contact-fail',
                        'page_name': 'Index',
                        'page_location': 'Footer',
                        'error_message': response.error // Optional: capture the error message
                    });
                    console.log(`Contact email FAIL EVENT`);
                }
            })
            .catch((error) => {
                console.error("AJAX Error:", error);
                document.getElementById("contact-console").style.color = "#f00404";
                document.getElementById("contact-console").innerHTML =
                    "Sorry, there was an error sending your message.";
                
                console.log(`Contact Email ERROR: ` + error.message);

                // Send failure event for AJAX errors
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'email-send-error',
                    'page_name': 'Index',
                    'page_location': 'Footer',
                    'error_message': error.message // Capture the error message
                });
                console.log(`Contact Email ERROR EVENT`);
            });
    });

    function POSTHandler(TotalMessage) {
        const urlToGet = "/php/contact.php";

        return fetch(urlToGet, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ contactTxt: TotalMessage }),
        })
            .then((response) => response.text()) // Read as text first
            .then((text) => {
                try {
                    return JSON.parse(text);
                } catch (error) {
                    console.error("Server returned non-JSON response:", text);
                    return { success: false, error: "Invalid response from server. Please email info@protonify.com." };
                }
            })
            .catch((error) => {
                console.error("POST Error:", error);
                return { success: false, error: "Network error or server unavailable. Please email info@protonify.com." };
            });
    }
})();*/